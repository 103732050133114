import { createInstance, enums, ReactSDKClient } from '@optimizely/react-sdk';
import { isEqual } from 'lodash';
import { OptimizelyDatafile } from 'lib/optimizely/types';

let cachedOptimizelyClient: ReactSDKClient | null;
const cachedNotificationListeners: {
  notificationType: enums.NOTIFICATION_TYPES;
  // TODO: Consider fixing linting error next time editing this file
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (notificationInfo: any) => void;
}[] = [];

export const getCachedOptimizelyClient = (): ReactSDKClient | null =>
  cachedOptimizelyClient;
/**
 * Gets an optimizely client instance. If one has already been initialized and the
 * datafile has not changed this will return the cached client.
 * We create a new instance when the datafile changes as this indicates a change in our
 * optimizely configuration - this happens when new tests are created.
 * @param datafile: the Optimizely data file
 * @param options: {
 *    @param notificationListeners: A list of notification listener configuration objects
 *    in the form {notificationType, callback}. These should be memoized where possible
 * }
 */
const getOptimizelyClient = (
  datafile: OptimizelyDatafile,
  options: {
    notificationListeners?: {
      notificationType: enums.NOTIFICATION_TYPES;
      // TODO: Consider fixing linting error next time editing this file
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (notificationInfo: any) => void;
    }[];
  } = {}
): ReactSDKClient => {
  const { notificationListeners = [] } = options;
  const didNotificationListenersChange = !isEqual(
    notificationListeners,
    cachedNotificationListeners
  );

  // If the datafile is defined and not empty, update the
  // cached datafile if it changed; we only cache valid datafiles.
  // This ensures we never overwrite valid datafiles.
  // Create an optimizelyClient only if there isn't one already or
  // if the data file changed; if there isn't a cachedOptimizelyClient
  // and the datafile is undefined or empty, just create a
  // cachedOptimizelyClient with an empty datafile. This way, every page
  // will always have an optimizelyClient with either
  // an empty datafile or a valid one fetched from optimizely.
  const optimizelyClient = cachedOptimizelyClient;

  cachedOptimizelyClient =
    cachedOptimizelyClient &&
    datafile?.revision ===
      cachedOptimizelyClient?.getOptimizelyConfig()?.revision
      ? cachedOptimizelyClient
      : createInstance({
          datafile: datafile || {},
        });

  const didClientChange = optimizelyClient !== cachedOptimizelyClient;

  // https://github.com/optimizely/react-sdk/issues/87
  // https://github.com/optimizely/react-sdk/issues/87#issuecomment-881506263
  // There are problems registering notification listeners after the client is ready
  if (didClientChange || didNotificationListenersChange) {
    cachedOptimizelyClient?.notificationCenter?.clearAllNotificationListeners();
    notificationListeners.forEach(({ callback, notificationType }) => {
      cachedOptimizelyClient?.notificationCenter?.addNotificationListener(
        notificationType,
        callback
      );
    });
  }

  return cachedOptimizelyClient;
};

export const clearCachedClient = (): void => {
  cachedOptimizelyClient = null;
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getOptimizelyClient;
