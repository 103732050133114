import { Dispatch } from 'react';
import { trackUserSource } from 'lib/registration/trackUserSource';
import { saveUser } from 'User/Actions';
import { UserData } from 'lib/registration/api';
import { setRegistrationCookies } from 'lib/registration/setRegistrationCookies';
import { NextRouter } from 'next/router';

// This function calls registration side effects common across registration flows,
// such as tracking, setting redux state, and setting cookies.
export const callRegistrationSideEffects = (
  dispatch: Dispatch<unknown>,
  query: NextRouter['query'],
  trackRegister = () => {},
  user: UserData
): void => {
  dispatch(saveUser(user.id, user.user_token, user.email));
  const fromPage = `${window.location.host}/${window.location.pathname}`;
  dispatch(trackUserSource(fromPage));
  setRegistrationCookies(
    user.email,
    user.id,
    user.user_token,
    query.documentType as string | undefined
  );
  trackRegister();
};
